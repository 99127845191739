import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import  VueGtag  from 'vue-gtag';





/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './registerServiceWorker'

import i18n from './i18n'


//i18n.global.locale.value = window.localStorage.getItem('lang');

//eggrafh gia to button tou sub kai unsub notific
const onesign = document.createElement('div');
onesign.setAttribute('class', 'onesignal-customlink-container');
document.head.appendChild(onesign);

if(window.localStorage.getItem('farvalue')==null){
  window.localStorage.setItem('farvalue', 5000);
}
window.localStorage.setItem('subcatSelected', 0);

const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(router)
  .use(VueGtag, {
    //config: {id: "G-BW13C060MX"}
    //config: {id: "UA-70290135-23"}
    config: {id: "G-016XWJ73YL"}
  })

router.isReady().then(() => {
  app.mount('#app');
});
