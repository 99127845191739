<template>
  <ion-page>
    <ion-tabs >
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar>
        <ion-tab-button tab="homepage" href="/tabs/homepage">
          <ion-icon :icon="home" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="maptab" href="/tabs/maptab">
          <ion-icon :icon="map"/>
          <ion-label>Map</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="arpage" href="/tabs/arpageintro/">
          <ion-icon :icon="videocam"/>
          <ion-label>AR</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="search" href="/tabs/Search">
          <ion-icon :icon="search" />
          <ion-label>Search</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="infopage" href="/tabs/infopage">
          <ion-icon :icon="informationCircleOutline" />
          <ion-label>Info</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { home, call, videocam, search, map, informationCircleOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n'

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {

    const { t } = useI18n({
      inheritLocale: true,
    })


    return {
      t,
      home, 
      call, 
      videocam,
      search,
      map,
      informationCircleOutline
    }
  },

  methods: {

    /*IOSPermissions(){
          if (typeof DeviceMotionEvent.requestPermission === 'function') {
              DeviceMotionEvent.requestPermission()
              .then(permissionState => {
                if (permissionState === 'granted') {
                  window.addEventListener('devicemotion', () => {});
                }
              })
              .catch(console.error);
          }

          if (typeof DeviceOrientationEvent.requestPermission === 'function') {
              DeviceOrientationEvent.requestPermission()
              .then(permissionState => {
                  if (permissionState === 'granted') {
                  window.addEventListener('deviceorientation', () => {});
                  }
              })
          .catch(console.error);}
        }*/
  }
}
</script>

<style scoped>

  ion-tab-bar{
    width: clamp(270px, 100vw, 500px);
    margin: auto;
  }

  ion-tab-button {
    --color-selected: var(--ion-color-galdarker);
 }

</style>