export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENGLISH"])},
  "hometab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "maptab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "artab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR"])},
  "searchtab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "infotab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "arpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Page"])},
  "settingsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AR Settings"])},
  "settingstxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data..."])},
  "loadingmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Map Data..."])},
  "searchtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "noresults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results..."])},
  "artext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW IN AR"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "applytxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "canceltxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "alerttextAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant the required permissions by clicking anywhere on the screen or the button on the top left"])},
  "alerttextheaderAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "pcHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "pcText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmented Reality is only available on mobile devices running Android or iOS"])},
  "distancetxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select max distance that items will show (in meters) - Recommended max is 2000"])},
  "cattxtsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories to show in AR"])},
  "pushnotif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to notifications to get the latest updates. Unsubscribe anytime!"])},
  "permissionsIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "info-par1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the app and the project"])},
  "info-par2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The historical and Commercial Center of our city, is a pole of attraction and love for the people of Thessaloniki of all generations and ages. As for the guests, love at first sight. This is because every corner of the Center gives the stigma of an Open City, in its tradition, its creation, its people. A heritage that for the Commercial Association of Thessaloniki means inspiration and development initiatives. The Thessaloniki Chamber of Commerce innovates and tries to support the city in these difficult times. The Open Trade Center of Thessaloniki D. Gounari - (Galerius) designed and is already taking its first steps and in "])},
  "info-par3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Open Trade Center is a demarcated space of independent shops and businesses that composes and puts into practice the belief of the commercial world of the city to offer the best possible services and products to the consumer public, making the area a competitive and growing pocket of the city."])},
  "info-par41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application is a city and market guide with connection to the e-marketplace "])},
  "info-par42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" with a wealth of information about the shops, hotels, health, transport of important historical and archaeological sites of the city as well as many useful ones such as Embassies, Consulates, etc. All this with an innovative presentation Augmented Reality (AR) "])},
  "info-par5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerius is a challenge - a bet for the Thessaloniki Chamber of Commerce, which aspires to create a model Open Trade Center - an example for all of Greece."])},
  "info-par6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application has been created IN THE FRAMEWORK OF THE INVITATION WITH THE TITLE: 'OPEN TRADE CENTERS' funded by the Business Program 'Competitiveness Entrepreneurship and Innovation 2014-2020'"])},
  "welcomear1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to AR"])},
  "welcomear2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Augmented Reality"])},
  "artext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Augmented Reality you can see the points of interest around you, in real time, through the camera of your device"])},
  "artext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you have the location of the device turned on and that you have granted the necessary permissions for the service to work"])},
  "artext3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you proceed to the AR page just lift your camera and look around. You will see icons from points of interest near you. Click on an icon to see details about it."])},
  "artext4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
  "Σημεία Ενδιαφέροντος": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points of interest"])},
  "Χρήσιμα": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful"])},
  "Υγεία": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health"])},
  "Μεταφορές": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation"])},
  "Τηλέφωνα SOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOS Phones"])},
  "Αγορά": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping"])},
  "Διασκέδαση": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
  "ΜΝΗΜΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONUMENTS"])},
  "ΕΚΚΛΗΣΙΕΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHURCHES"])},
  "ΚΑΤΑΣΤΗΜΑΤΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STORES"])},
  "ΜΟΥΣΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUSEUMS"])},
  "ΑΞΙΟΘΕΑΤΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGHTS"])},
  "ΦΑΡΜΑΚΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHARMACIES"])},
  "ΙΑΤΡΟΙ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCTORS"])},
  "ΝΟΣΟΚΟΜΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPITALS"])},
  "ΚΕΝΤΡΑ ΥΓΕΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEALTH CENTRES"])},
  "ΠΕΡΙΦΕΡΕΙΑΚΑ ΙΑΤΡΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGIONAL CLINICS"])},
  "ΨΥΧΙΑΤΡΙΚΕΣ ΔΟΜΕΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSYCHIATRIC STRUCTURES"])},
  "ΜΟΝΑΔΕΣ ΥΓΕΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEALTH UNITS"])},
  "ΞΕΝΟΔΟΧΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOTELS"])},
  "ΚΑΦΕΤΕΡΙΕΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAFE"])},
  "ΕΣΤΙΑΤΟΡΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESTAURANTS"])},
  "ΤΗΛΕΦΩΝΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONES"])},
  "ΠΡΟΞΕΝΕΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATES"])},
  "ΤΑΞΙ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAXI"])},
  "ΑΣΤΙΚΑ ΛΕΩΦΟΡΕΙΑ ΘΕΣΣΑΛΟΝΙΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URBAN BUSES OF THESS"])},
  "ΤΗΛΕΦΩΝΑ SOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOS PHONES"])},
  "ΘΕΑΤΡΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THEATRES"])},
  "ΣΙΝΕΜΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CINEMA"])},
  "ΥΠΗΡΕΣΙΕΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICES"])},
  "ΤΡΕΝΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAINS"])},
  "ΑΕΡΟΔΡΟΜΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIRPORTS"])},
  "ΠΛΟΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHIPS"])},
  "ΑΝΤΙΚΑΡΚΙΝΙΚΟ ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'ΘΕΑΓΕΝΕΙΟ'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTI-CANCER HOSPITAL OF THESSALONIKI 'THEAGENEIO'"])},
  "Αλεξ. Συμεωνίδη 2, 540 07 Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alex. Symeonidi 2, 540 07 Thessaloniki"])},
  "ΓΕΝΙΚΟ ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'Ο ΑΓΙΟΣ ΠΑΥΛΟΣ'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERAL HOSPITAL OF THESSALONIKI 'AGIOS PAVLOS'"])},
  "Εθν. Αντιστάσεως  161, 551 34 Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethn. Antistaseos 161, 551 34 Thessaloniki"])},
  "ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'ΓΕΩΡΓΙΟΣ ΓΕΝΝΗΜΑΤΑΣ'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPITAL OF THESSALONIKI 'GEORGIOS GENNIMATAS'"])},
  "Εθνικής Αμύνης 41, Θεσσαλονίκη 546 35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethnikis Aminis 41, Thessaloniki 546 35"])},
  "ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'Ο ΑΓΙΟΣ ΔΗΜΗΤΡΙΟΣ'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THESSALONIKI HOSPITAL 'AGIOS DIMITRIOS'"])},
  "Ελένης Ζωγράφου 2, Θεσσαλονίκη 546 34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenis Zografou 2, Thessaloniki 546 34"])},
  "ΓΕΝΙΚΟ ΝΟΣΟΚ ΘΕΣΣ/ΝΙΚΗΣ 'ΙΠΠΟΚΡΑΤΕΙΟ'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERAL HOSPITAL OF THESSALONIKI 'IPPOKRATIO'"])},
  "Κωνσταντινουπόλεως 49, 546 42 Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstantinoupoleos 49, 546 42 Thessaloniki"])},
  "ΚΕΝΤΡΟ ΥΓΕΙΑΣ ΤΟΥΜΠΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOUBAS HEALTH CENTER"])},
  "ΚΕΝΤΡΟ ΥΓΕΙΑΣ 25ΗΣ ΜΑΡΤΙΟΥ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEALTH CENTER 25TH MARCH"])},
  "Εθν.Αντιστάσεως 62, 55133 Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethn. Antistaseos 62, 55133 Thessaloniki"])},
  "ΚΕΝΤΡΟ ΥΓΕΙΑΣ ΔΙΑΒΑΤΩΝ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIAVATA HEALTH CENTER"])},
  "Ελ. Βενιζέλου 5, Διαβατά 570  08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. Venizelou 5, Diavata 570 08"])},
  "ΚΕΝΤΡΟ ΨΥΧΙΚΗΣ ΥΓΕΙΑΣ ΚΕΝΤΡΙΚΟΥ ΤΟΜΕΑ ΘΕΣ/ΝΙΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CENTRAL MENTAL HEALTH CENTER THESSALONIKI"])},
  "Καρολή & Δημητρίου 1,  Θεσσαλονίκη 546 30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karoli & Dimitriou 1, Thessaloniki 546 30"])},
  "ΚΕΝΤΡΟ ΨΥΧΙΚΗΣ ΥΓΕΙΑΣ ΒΟΡΕΙΟΔΥΤΙΚΟΥ ΤΟΜΕΑ ΘΕΣ/ΝΙΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENTAL HEALTH CENTER NORTHWEST SECTOR THESSALONIKI"])},
  "Παπαδοπούλου 20, Συκιές 566 25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papadopoulou 20, Sykies 566 25"])},
  "Λεωφόρος Μεγάλου Αλεξάνδρου 2, Θεσσαλονίκη 546 40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megalou Alexandrou Avenue 2, Thessaloniki 546 40"])},
  "13ο χιλιόμετρο Θεσσαλονίκης-Περαίας, Θεσσαλονίκη 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13th kilometer of Thessaloniki-Perea, Thessaloniki 5"])},
  "ΑΓΙΑ ΣΟΦΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGIA SOFIA"])},
  "Αγίας Σοφίας, Θεσσαλονίκη 546 22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agias Sofias, Thessaloniki 546 22"])},
  "ΠΛΑΤΕΙΑ ΑΡΙΣΤΟΤΕΛΟΥΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLATEIA ARISTOTELOUS"])},
  "ΡΩΜΑΪΚΗ ΑΓΟΡΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROMAIKI AGORA"])},
  "Ολύμπου 75, Θεσσαλονίκη 546 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olimpou 75, Thessaloniki 546 31"])},
  "ΛΕΥΚΟΣ ΠΥΡΓΟΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEYKOS PYRGOS"])},
  "Λεωφόρος Νίκης, Θεσσαλονίκη 546 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leoforos Nikis, Thessaloniki 546 21"])},
  "ΑΡΧΑΙΟΛΟΓΙΚΟ ΜΟΥΣΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARCHAELOGICAL MUSEUM OF THESSALONIKI"])},
  "Μανώλη Ανδρονίκου 6, Θεσσαλονίκη 546 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manoli Andronikou 6, Thessaloniki 546 21"])},
  "'YAMAMAY' ΠΑΡΑΣΚΕΥΗ ΖΑΦΕΙΡΑΚΗ & ΣΙΑ EE ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'YAMAMAY' PARASKEVI ZAFIRAKI & SIA EE"])},
  "Τσιμισκή 97, Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsimiski 97, Thessaloniki"])},
  "'RIN TIN TIN' ΜΕΛΛΙΟΣ ΘΩΜ. ΚΩΝΣΤΑΝΤΙΝΟΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'RIN TIN TIN' MELLIOS THOMAS KONSTANTINOS"])},
  "Τσιμισκή 113, Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsimiski 113, Thessaloniki"])},
  "'I LOVE THESSALONIKI' - ΒΑΡΑΧΙΔΟΥ Γ. ΕΥΡΥΔΙΚΗ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'RIN TIN TIN' VARAHIDOY G. EVRIDIKI"])},
  "Δημητρίου Γούναρη 28, Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimitrioy Goynari 28, Thessaloniki"])},
  "'SENSO DI METALO' - ΑΝΑΣΤΑΣΙΟΣ ΕΛΕΥΘ. ΜΗΤΡΟΥΣΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'SENSO DI METALO' ANASTASIOS ELEFTH. MITROYSIS"])},
  "Αλεξάνδρου Σβώλου 38, Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandrou Svolou 38, Thessaloniki"])},
  "ΡΑΔΙΟΤΑΞΙ TAXIWAΥ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RADIOTAXI TAXIWAY"])},
  "ΡΑΔΙΟΤΑΞΙ MERCEDES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RADIOTAXI MERCEDES"])},
  "ΡΑΔΙΟΤΑΞΙ ΜΑΚΕΔΟΝΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RADIOTAXI MACEDONIA"])},
  "ΟΑΣΘ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OASTH"])},
  "ΕΠΙΒΑΤΙΚΟΣ ΣΤΑΘΜΟΣ ΘΕΣΣΑΛΟΝΙΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PASSENGER STATION OF THESSALONIKI"])},
  "ΓΡΑΦΕΙΟ ΤΑΞΙΔΙΩΝ ΚΑΙ ΤΟΥΡΙΣΜΟΥ ΤΡΑΙΝΟΣΕ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFFICE OF TRIPS AND TOURISM TRAINOSE"])},
  "Αριστοτέλους 18, 546 23,  Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aristotelous 18, 546 23, Thessaloniki"])},
  "ΔΙΕΘΝΕΣ ΑΕΡΟΔΡΟΜΙΟ ΜΑΚΕΔΟΝΙΑ (SKG)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERNATIONAL AIRPORT MACEDONIA (SKG)"])},
  "ΛΙΜΑΝΙ ΘΕΣΣΑΛΟΝΙΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PORT OF THESSALONIKI"])},
  "ΑΣΤΙΚΗ ΣΥΓΚΟΙΝΩΝΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URBAN TRANSPORT"])},
  "Κ.Τ.Ε.Λ.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K.T.E.L."])},
  "ΑΕΡΟΔΡΟΜΙΟ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIRPORT"])},
  "ΝΟΣΟΚΟΜΕΙΟ ΘΕΣ/ΝΙΚΗΣ ΠΑΠΑΝΙΚΟΛΑΟΥ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAPANIKOLAOU HOSPITAL OF THESSALONIKI"])},
  "Λεωφ. Παπανικολάου, Πυλαία-Χορτιάτης 570 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leoforos Papanikolaou, Pylaia Xortiatis 570 10"])},
  "ΝΟΣΟΚΟΜΕΙΟ ΘΕΣ/ΝΙΚΗΣ Α.Χ.Ε.Π.Α": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AHEPA HOSPITAL OF THESSALONIKI"])},
  "Στίλπωνος Κυριακίδη 1, Θεσσαλονίκη 546 36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilponos Kiriakidi 1, Thessaloniki 546 36"])},
  "ΝΟΣΟΚΟΜΕΙΟ ΑΦΡΟΔΙΣΙΩΝ ΚΑΙ ΔΕΡΜΑΤΙΚΩΝ ΝΟΣΩΝ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPITAL OF SKIN AND VENUS DISEASES"])},
  "ΘΕΡΑΠΕΥΤΗΡΙΟ ΧΡΟΝΙΩΝ ΠΑΘΗΣΕΩΝ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHRONIC DISEASE TREATMENT CLINIC"])},
  "ΨΥΧΙΑΤΡΙΚΟ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSYCHIATRIC"])},
  "ΝΟΣΟΚΟΜΕΙΟ ΠΑΠΑΓΕΩΡΓΙΟΥ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAPAGEORGIOY HOSPITAL"])},
  "ΠΡΟΞΕΝΕΙΟ ΓΕΩΡΓΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF GEORGIA"])},
  "Θεμιστοκλή Σοφούλη 3, 55131, Θεσσαλονίκη": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themistokli Sofouli 3, 55131, Thessaloniki"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΔΗΜΟΚΡΑΤΙΑΣ ΒΟΥΛΓΑΡΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF BULGARIA"])},
  "Μάνου Νικολάου 12 & Άββοτ Εδμόνδου 1, 54646, Θεσσα": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manou Nikolaou 12 & Abbot Edmondou 1, 54646, Thessaloniki"])},
  "ΠΡΟΞΕΝΕΙΟ ΡΩΣΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF RUSSIA"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΙΤΑΛΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF ITALY"])},
  "ΠΡΟΞΕΝΕΙΟ ΑΥΣΤΡΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF AUSTRIA"])},
  "ΠΡΟΞΕΝΕΙΟ ΙΡΛΑΝΔΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF IRELAND"])},
  "ΠΡΟΞΕΝΕΙΟ ΠΟΡΤΟΓΑΛΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF PORTUGAL"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΦΙΛΑΝΔΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF FINLAND"])},
  "ΠΡΟΞΕΝΕΙΟ ΙΣΠΑΝΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF SPAIN"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΑΥΣΤΡΑΛΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF AUSTRALIA"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΕΛΒΕΤΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF SWITZERLAND"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΟΥΚΡΑΝΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF UKRAINE"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΤΗΣ ΟΜΟΣΠΟΝΔΙΑΚΗΣ ΓΕΡΜΑΝΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF GERMANY"])},
  "ΠΡΟΞΕΝΕΙΟ ΦΙΛΙΠΠΙΝΩΝ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF PHILIPPINES"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΚΥΠΡΙΑΚΗΣ ΔΗΜΟΚΡΑΤΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF CYPRUS"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΔΗΜΟΚΡΑΤΙΑΣ ΤΗΣ ΣΕΡΒΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF SERBIA"])},
  "ΠΡΟΞΕΝΕΙΟ ΠΟΛΩΝΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF POLAND"])},
  "ΠΡΟΞΕΝΕΙΟ ΚΑΝΑΔΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF CANADA"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΒΕΛΓΙΟΥ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF BELGIUM"])},
  "ΠΡΟΞΕΝΕΙΟ ΣΟΥΗΔΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF SWEDEN"])},
  "ΒΑΣΙΛΙΚΟ ΓΕΝΙΚΟ ΝΟΡΒΗΓΙΚΟ ΠΡΟΞΕΝΕΙΟ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF NORWAY"])},
  "ΠΡΟΞΕΝΕΙΟ ΔΑΝΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF DENMARK"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΚΑΖΑΚΣΤΑΝ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF KAZAKHSTAN"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΚΟΡΕΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF KOREA"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΑΛΒΑΝΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF ALBANIA"])},
  "ΠΡΟΞΕΝΕΙΟ ΟΥΓΓΑΡΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF HUNGARY"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΗΠΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF THE USA"])},
  "ΓΕΝΙΚΟ ΠΡΟΞΕΝΕΙΟ ΓΑΛΛΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF FRANCE"])},
  "ΠΡΟΞΕΝΕΙΟ ΤΟΥΡΚΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF TURKEY"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΒΡΑΖΙΛΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF BRAZIL"])},
  "ΕΠΙΤΙΜΟ ΠΡΟΞΕΝΕΙΟ ΚΡΟΑΤΙΑΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSULATE OF CROATIA"])},
  "ΕΥΡΩΠΑΙΚΟΣ ΑΡΙΘΜΟΣ ΕΚΤΑΚΤΗΣ ΑΝΑΓΚΗΣ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUROPEAN NUMBER OF EMERGENCY"])},
  "ΑΣΤΥΝΟΜΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLICE"])},
  "ΤΟΥΡΙΣΤΙΚΗ ΑΣΤΥΝΟΜΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOURISM POLICE"])},
  "ΠΥΡΟΣΒΕΣΤΙΚΗ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIRE STATION"])},
  "ΕΚΑΒ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOSPITAL EMERGENCY"])},
  "ΛΙΜΕΝΑΡΧΕΙΟ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PORT AUTHORITY"])},
  "ΤΡΟΧΑΙΑ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAFFIC POLICE"])}
}