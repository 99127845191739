<template>
  <ion-app>
    <keep-alive>
      <ion-router-outlet />
    </keep-alive>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  created(){
    if(navigator.platform.includes('win') || navigator.platform.includes('Win')){
      localStorage.setItem('isDesktop', 1);
    }
    else{
      localStorage.setItem('isDesktop', 0);
    }
    let isIOS = this.checkIOS();
    if(isIOS==true){
      localStorage.setItem('isIOS', 1);
    }
    else{
      localStorage.setItem('isIOS', 0);
    }
    
  },

  methods: {
    checkIOS(){
          return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
  }
 
});
</script>