export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλωσήρθατε"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΕΛΛΗΝΙΚΑ"])},
  "hometab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχική"])},
  "maptab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χάρτης"])},
  "artab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ε.Π."])},
  "searchtab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
  "infotab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
  "arpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σελίδα Ε.Π."])},
  "settingsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις AR"])},
  "settingstxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση δεδομένων..."])},
  "loadingmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση Χάρτη..."])},
  "searchtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
  "noresults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανένα αποτέλεσμα..."])},
  "artext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΝΟΙΓΜΑ ΣΕ ΕΠ"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες"])},
  "applytxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογη"])},
  "canceltxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακυρο"])},
  "alerttextAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δώστε τις ζητούμενες άδειες πατώντας οπουδήποτε πάνω στην οθόνη ή το κουμπί που βρίσκεται πάνω αριστερά"])},
  "alerttextheaderAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσοχή"])},
  "pcHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσοχή"])},
  "pcText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επαυξημένη πραγματικότητα είναι διαθέσιμη μόνο για κινητές συσκυές Android ή iOS"])},
  "distancetxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τη μέγιστη απόσταση που θέλετε να εμφανίζονται τα σημεία AR. Προτεινόμενο μέγιστο 2000"])},
  "cattxtsettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορίες για AR"])},
  "pushnotif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε εγγραφή για να λαμβάνετε ειδοποιήσεις. Απενεργοποιείστε οποιαδήποτε στιγμή επιθυμείτε!"])},
  "permissionsIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άδειες"])},
  "info-par1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες για την Εφαρμογή και το έργο"])},
  "info-par2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ιστορικό και Εμπορικό Κέντρο της πόλης μας αποτελεί πόλο έλξης και αγάπης για τους θεσσαλονικείς κάθε γενιάς και ηλικίας. Όσον αφορά τους επισκέπτες, έρωτας με τη πρώτη ματιά. Αυτό συμβαίνει γιατί κάθε γωνιά του Κέντρου δίνει το στίγμα μιας Ανοικτής πόλης, στη παράδοση, τη δημιουργία, τους  ανθρώπους της. Μια κληρονομιά που για τον Εμπορικό  Σύλλογο  Θεσσαλονίκης σημαίνει έμπνευση και αναπτυξιακές πρωτοβουλίες. Ό Εμπορικός Σύλλογος Θεσσαλονίκης καινοτομεί και προσπαθεί σ´ αυτές τις δύσκολες στιγμές  να στηρίξει τη πόλη. Σχεδίασε και ήδη κάνει τα πρώτα του βήματα το Ανοικτό Κέντρο Εμπορίου της Θεσσαλονίκης Δ. Γούναρη - (Galerius)  και στο "])},
  "info-par3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Ανοικτό Κέντρο Εμπορίου είναι ένας οριοθετημένος χώρος ανεξάρτητων καταστημάτων και επιχειρήσεων  ο οποίος συνθέτει και κάνει πράξη  τη πεποίθηση του εμπορικού κόσμου της πόλης να προσφέρει τις καλύτερες δυνατές υπηρεσίες και προϊόντα στο καταναλωτικό κοινό, καθιστώντας τη περιοχή έναν ανταγωνιστικό και αναπτυξιακό θύλακα της πόλης."])},
  "info-par41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η παρούσα εφαρμογή αποτελεί έναν οδηγό πόλης και αγοράς  με σύνδεση καιστο e-marketplace "])},
  "info-par42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" με πληθώρα πληροφοριών σχετικά με τα καταστήματα, τα ξενοδοχεία, την υγεία , τις μεταφορές των σημαντικών ιστορικών και αρχαιολογικών σημείων της πόλης καθώς και πολλών χρήσιμων  όπως Πρεσβείες, Προξενεία κλπ.Όλα αυτά και με μία πρωτοποριακή παρουσίαση Augmented Reality  (AR)"])},
  "info-par5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Galerius  αποτελεί μια πρόκληση-ένα στοίχημα για τον Εμπορικό Σύλλογο Θεσσαλονίκης, ο οποίος φιλοδοξεί να δημιουργήσει ένα πρότυπο Ανοικτό Κέντρο Εμπορίου-παράδειγμα για όλη την Ελλάδα."])},
  "info-par6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εφαρμογή έχει δημιουργηθεί  ΣΤΌ ΠΛΑΙΣΙΌ ΤΗΣ  ΠΡΌΣΚΛΗΣΗΣ  ΜΕ ΤΙΤΛΌ :  «ΑΝΌΙΚΤΑ ΚΕΝΤΡΑ ΕΜΠΌΡΙΌΥ»  που χρηματοδοτείται από το Επιχειρησιακό Πρόγραμμα «Ανταγωνιστικότητα Επιχειρηματικότητα και Καινοτομία 2014-2020."])},
  "welcomear1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλωσήρθατε στην Ε.Π."])},
  "welcomear2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλωσήρθατε στην Επαυξημένη Πραγματικότητα!"])},
  "artext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την Επαυξημένη Πραγματικότητα μπορείτε να δείτε τα σημεία ενδιαφέροντος που βρίσκονται τριγύρω σας, σε πραγματικό χρόνο, μέσω της κάμερας της συσκευής σας"])},
  "artext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σιγουρευτείτε ότι έχετε ενεργοποιημένη την τοποθεσία της συσκευής και ότι έχετε παραχωρήσει τις απαραίτητες άδειες προκειμένου να λειτουργήσει η υπηρεσία"])},
  "artext3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν θα προχωρήσετε στη σελίδα Ε.Π. απλά σηκώστε την κάμερά σας και κοιτάξτε τριγύρω. Θα δείτε εικονίδια από σημεία ενδιαφέροντος που βρίσκονται κοντά σας. Κάντε κλικ πάνω σε κάποιο εικονίδιο για να δείτε λεπτομέρειες για αυτό."])},
  "artext4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλέφωνο"])},
  "www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστοσελίδα"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τοποθεσία"])},
  "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά"])},
  "Points of interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημεία Ενδιαφέροντος"])},
  "Health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υγεία"])},
  "Useful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήσιμα"])},
  "SOS Phones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλέφωνα SOS"])},
  "Shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά"])},
  "Entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διασκέδαση"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΟΛΑ"])},
  "MONUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΜΝΗΜΕΙΑ"])},
  "CHURCHES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΕΚΚΛΗΣΙΕΣ"])},
  "STORES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΑΤΑΣΤΗΜΑΤΑ"])},
  "MUSEUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΜΟΥΣΕΙΑ"])},
  "SIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΞΙΟΘΕΑΤΑ"])},
  "PHARMACIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΦΑΡΜΑΚΕΙΑ"])},
  "DOCTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΙΑΤΡΟΙ"])},
  "HOSPITALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΝΟΣΟΚΟΜΕΙΑ"])},
  "HEALTH CENTRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΕΝΤΡΑ ΥΓΕΙΑΣ"])},
  "REGIONAL CLINICS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΠΕΡΙΦΕΡΕΙΑΚΑ ΙΑΤΡΕΙΑ"])},
  "PSYCHIATRIC STRUCTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΨΥΧΙΑΤΡΙΚΕΣ ΔΟΜΕΣ"])},
  "HEALTH UNITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΜΟΝΑΔΕΣ ΥΓΕΙΑΣ"])},
  "HOTELS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΞΕΝΟΔΟΧΕΙΑ"])},
  "CAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΑΦΕΤΕΡΙΕΣ"])},
  "RESTAURANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΕΣΤΙΑΤΟΡΙΑ"])},
  "PHONES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΤΗΛΕΦΩΝΑ"])},
  "CONSULATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΠΡΟΞΕΝΕΙΑ"])},
  "TAXI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΤΑΞΙ"])},
  "URBAN BUSES OF THESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΣΤΙΚΑ ΛΕΩΦΟΡΕΙΑ ΘΕΣΣΑΛΟΝΙΚΗΣ"])},
  "SOS PHONES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΤΗΛΕΦΩΝΑ SOS"])},
  "ANTI-CANCER HOSPITAL OF THESSALONIKI 'THEAGENIO'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΝΤΙΚΑΡΚΙΝΙΚΟ ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'ΘΕΑΓΕΝΕΙΟ'"])},
  "A.H.TH. THEAGENEIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΝΘ ΘΕΑΓΕΝΕΙΟ"])},
  "GENERAL HOSPITAL OF THESSALONIKI 'AGIOS PAVLOS'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΓΕΝΙΚΟ ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'Ο ΑΓΙΟΣ ΠΑΥΛΟΣ'"])},
  "HOSPITAL OF THESSALONIKI 'GEORGIOS GENNIMATAS'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'ΓΕΩΡΓΙΟΣ ΓΕΝΝΗΜΑΤΑΣ'"])},
  "THESSALONIKI HOSPITAL 'AGIOS DIMITRIOS'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΝΟΣΟΚΟΜΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ 'Ο ΑΓΙΟΣ ΔΗΜΗΤΡΙΟΣ'"])},
  "GENERAL HOSPITAL OF THESSALONIKI 'IPPOKRATIO'": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΓΕΝΙΚΟ ΝΟΣΟΚ ΘΕΣΣ/ΝΙΚΗΣ 'ΙΠΠΟΚΡΑΤΕΙΟ'"])},
  "TOUBAS HEALTH CENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΕΝΤΡΟ ΥΓΕΙΑΣ ΤΟΥΜΠΑΣ"])},
  "HEALTH CENTER 25TH MARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΕΝΤΡΟ ΥΓΕΙΑΣ 25ΗΣ ΜΑΡΤΙΟΥ"])},
  "DIAVATA HEALTH CENTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΕΝΤΡΟ ΥΓΕΙΑΣ ΔΙΑΒΑΤΩΝ"])},
  "CENTRAL MENTAL HEALTH CENTER THESSALONIKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΕΝΤΡΟ ΨΥΧΙΚΗΣ ΥΓΕΙΑΣ ΚΕΝΤΡΙΚΟΥ ΤΟΜΕΑ ΘΕΣ/ΝΙΚΗΣ"])},
  "MENTAL HEALTH CENTER NORTHWEST SECTOR THESSALONIKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΚΕΝΤΡΟ ΨΥΧΙΚΗΣ ΥΓΕΙΑΣ ΒΟΡΕΙΟΔΥΤΙΚΟΥ ΤΟΜΕΑ ΘΕΣ/ΝΙΚΗΣ"])}
}