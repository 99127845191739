import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue'

const routes = [
  {
    path: '/',
    redirect: '/tabs/homepage'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/homepage'
      },
      {
        path: 'homepage',
        component: () => import('@/views/homepage.vue')
      },
      {
        path: 'arpage',
        component: () => import('@/views/arpage.vue')
      },
      {
        path: 'arpageintro',
        component: () => import('@/views/arpageintro.vue')
      },
      {
        path: 'infopage',
        component: () => import('@/views/infopage.vue')
      },
      {
        path: 'Search',
        component: () => import('@/views/Search.vue')
      },
      {
        path: 'MapTab',
        component: () => import('@/views/MapTab.vue')
      }
    ]
  },
  {
    path: '/tabs/Search/detailspagesearch/:id',
    name: 'detailspage',
    component: () => import('@/views/DetailsPage.vue')
  },
  {
    path: '/tabs/homepage/SearchPageCategories/:idcat/detailspage/:id',
    name: 'detailspagehome',
    component: () => import('@/views/DetailsPage.vue')
  },
  {
    path: '/tabs/homepage/SearchPageCategories/:id',
    name: 'categoriespage',
    component: () => import('@/views/SearchPageCategories.vue')
  },
  {
    path: '/tabs/:id/arwindow/',
    name: 'ar',
    component: () => import('@/views/ArWindowPage.vue')
  },
  {
    path: '/tabs/arpage/settingsar/',
    name: 'settingsar',
    component: () => import('@/views/SettingsAR.vue')
  },
  {
    path: '/tabs/infopage/generalsettings/',
    name: 'generalsettings',
    component: () => import('@/views/GeneralSettings.vue')
  },
  

 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
